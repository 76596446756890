`<template>

  <div>
    <!--- loading state -->
    <template v-if="isLoading">

      <div class="text-center">
        <b-spinner variant="primary" label="Loading" />
      </div>

    </template>
    <!-- data section -->
    <template v-else>


      <!-- error section -->
      <template v-if="userData.length == 0">
        <b-alert
            variant="danger"
            show
        >
          <h4 class="alert-heading">Error al obtener los datos del usuario</h4>
          <div class="alert-body">
            <h6>
            El usuario correspondiente a este ID no ha sido encontrado. Por favor, revise la lista de usuarios para ver otras opciones.
            </h6>
            <b-button
                variant="primary"
                @click="handleBack"
            >
              Volver a la lista
            </b-button>
          </div>



        </b-alert>
      </template>

      <!-- data  -->
      <template v-else>

        <!-- modo edicion -->
        <template v-if="isEditMode">

          <usuario-edit
              :usuario-prop="userDataProp"
              @closeEditMode="handleEdit(false)"
              @successEditMode="handleSuccessEdit( false )"
          />

        </template>

        <!-- modo lectura -->
        <template v-else>

          <!-- info user -->
          <b-card>

            <!-- back -->
            <b-row>
              <b-col>
                <b-button
                    variant="link"
                    class="p-0"
                    @click="handleBack"
                    :disabled="isBusy"
                >
                  <feather-icon
                      icon="ArrowLeftIcon"
                      class="mr-50"
                  />
                  <span class="align-middle">Regresar</span>
                </b-button>
              </b-col>
            </b-row>


            <b-row class="mt-2">
              <b-col>
                <h3>{{ userData.usrNombres }} {{ userData.usrApellidos }}</h3>
                <span>{{ userData.email }}</span>
              </b-col>
            </b-row>

            <b-row class="mt-2">

              <b-col cols="12">
                <table>
                  <tr>
                    <th>
                      <span class="font-weight-bold mr-50">Rol</span>
                    </th>
                    <td>
                      <div class="text-nowrap ml-10">
                        <feather-icon
                            :icon="getTypeIcon(userData.usrType)"
                            size="18"
                            class="mr-50"
                            :class="`text-${getTypeVariant(userData.usrType)}`"
                        />
                        <span class="align-text-top text-capitalize">{{ getTypeName( userData.usrType ) }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span class="font-weight-bold mr-50">Estado</span>
                    </th>
                    <td>
                      <div class="text-nowrap">
                        <feather-icon
                            icon="CheckIcon"
                            size="18"
                            class="mr-50"
                            :class="`text-${getStatusVariant(userData.usrEstado)}`"
                        />
                        <span class="align-text-top text-capitalize">{{ getStatusName( userData.usrEstado )  }}</span>
                      </div>
                    </td>
                  </tr>
                </table>
              </b-col>

            </b-row>

            <b-row class="mt-2">
              <b-col>
                <b-button
                    variant="primary"
                    @click="handleEdit( true )"
                    :disabled="isBusy"
                >
                  Editar
                </b-button>
              </b-col>
            </b-row>

          </b-card>

          <!-- danger zone -->
          <b-card title="Zona de riesgo">
            ¡Atención! Está a punto de eliminar un usuario de forma permanente. Esta acción no puede deshacerse. Por favor, asegúrese de que desea continuar antes de hacer clic en el botón "Eliminar".
            <b-row class="mt-2">
              <b-col>
                <b-button
                    variant="danger"
                    @click="handleDelete"
                    :disabled="isBusy"
                >
                  <template v-if="isBusy">
                    <b-spinner small />
                    Eliminando...
                  </template>
                  <template v-else>
                    Eliminar usuario
                  </template>
                </b-button>

              </b-col>
            </b-row>
          </b-card>

          <!-- unlock user -->
          <unlock-user :usuario-id="userData.id" v-if="isBannedUser"/>



        </template>


      </template>



    </template>


  </div>

</template>

<script>

import { BAlert, BButton, BCard, BCardBody, BCol, BLink, BRow, BSpinner } from 'bootstrap-vue'

import {
  resolveStatusName,
  resolveStatusVariant,
  resolveTypeIcon,
  resolveTypeName,
  resolveTypeVariant
} from '@/modules/common/utils'

import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'UsuarioView',
  components: {
    BAlert,
    BLink,
    BSpinner,
    BButton,
    BCard,
    BCardBody,
    BRow,
    BCol,
    UsuarioEdit: () => import('@/modules/ejecutante/views/catalogos/usuarios/UsuarioEdit.vue'),
    UnlockUser: () => import('@/modules/seguridad/componentes/UnlockUser.vue')
  },
  data(){
    return {

      isLoading: false,
      isEditMode: false,
      isBusy: false,
      userData: []

    }
  },
  computed:{

    queryUuid(){

      const { uuid } = this.$route.query

      return uuid ?? null

    },

    userDataProp(){
      return { ...this.userData }
    },

    isBannedUser(){
      return true
      return !this.userData.usrBannedAt === null
    }



  },
  methods:{

    ...mapActions('usuario-module', ['findOne', 'deleteUsuario']),

    // buscar informacion del usuario
    async loadData(){

      try {

        this.userData = []

        this.isLoading = true

        this.userData = await this.findOne({ 'uuid': this.queryUuid })

        this.isLoading = false

      } catch ( error ) {




      } finally {
        this.isLoading = false
      }

    },

    // redireccionar a la lista
    handleBack(){
      this.$router.push({name: 'catalogo-usuarios'})
    },

    // procesar accion edit
    handleEdit( editMode ){
      this.isEditMode = editMode
    },

    // procesar accion edit exitosa
    async handleSuccessEdit( editMode ){
      await this.loadData()
      this.isEditMode = editMode
    },

    // procesar accion delete
    async handleDelete(){

      const isConfirm = await  this.$bvModal.msgBoxConfirm(
          '¿Estás seguro que deseas eliminar este usuario? Esta acción es irreversible y eliminará toda su información.',
          {
            title: 'Eliminar usuario',
            okVariant: 'danger',
            okTitle: 'Sí, eliminar usuario',
            cancelTitle: 'No',
            cancelVariant: 'secondary',
            modalClass: "modal-danger",
            hideHeaderClose: false,
            centered: true,
          })

      if ( ! isConfirm )  return

      try {

        this.isBusy = true

        this.deleteUsuario( this.userData.usrUuid )

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Operación exitosa',
            icon: 'CheckIcon',
            text: 'La eliminación del usuario se ha realizado correctamente.',
            variant: 'success'
          },
        })

        this.isBusy = false

        this.$router.push({name: 'catalogo-usuarios'})

      } catch ( error ) {

      }


    },



    //

    getStatusVariant( status ){
      return resolveStatusVariant( status )
    },

    getStatusName( status ) {
      return resolveStatusName( status )
    },

    getTypeName( type ) {
      return resolveTypeName( type )
    },

    getTypeVariant( type ){
      return resolveTypeVariant( type )
    },
    getTypeIcon( type ){
      return resolveTypeIcon( type )
    },


  },
  async created() {
    await this.loadData()
  }
}
</script>

<style scoped>

</style>
